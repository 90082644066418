<template>
  <div class="w-10/12 mx-auto mb-12">
    <div class="flex gap-6 justify-between py-6">
      <div class="w-5/12">
        <h2 class="text-4xl">All Products</h2>
        <p>
          Shop for Cartridges, Needles, Mixers, Speakers, MPC’s and more in our
          webshop.
        </p>
      </div>
      <div class="w-6/12 xl:w-5/12">
        <h3 class="text-sm text-center mb-6">CATEGORIES</h3>
        <category-selector
          v-model:category="filter.category"
          :categories="categories"
        ></category-selector>
      </div>
    </div>
    <section id="catalogue-list">
      <div class="mb-6">
        <catalogue-list-header
          :isTight="isListTight"
          :lengthList="displayProducts.length"
          :category="filter.category"
          @changeListView="(e) => (isListTight = e)"
          @clearCategoryFilter="filter.category = ''"
        ></catalogue-list-header>
      </div>
      <catalogue-list
        :products="displayProducts"
        :isTight="isListTight"
      ></catalogue-list>
    </section>
  </div>
</template>

<script>
import {
  CategorySelector,
  CatalogueListHeader,
  CatalogueList,
} from '@/components';
import { markRaw } from 'vue';

import { catalogue } from '@/static/catalogue.js';

export default {
  name: 'Catalogue',
  setup() {
    const c_atalogue = markRaw(catalogue);

    return {
      c_atalogue,
    };
  },
  components: {
    CategorySelector,
    CatalogueListHeader,
    CatalogueList,
  },
  data() {
    return {
      filter: {
        category: '',
        relevance: '',
      },
      isListTight: false,
    };
  },
  computed: {
    displayProducts: function() {
      let _products = this.c_atalogue;

      if (this.filter.category) {
        _products = this.c_atalogue.filter((product) =>
          product.categories.some(({ name }) => name === this.filter.category),
        );
      }

      return _products;
    },
    categories: function() {
      let catMap = {};
      let ignore = ['Turntables', 'Cartridge', 'Cables', 'Accessories'];

      this.c_atalogue.forEach((product) => {
        let { name: cat } = product.categories.filter(
          ({ name }) => name !== 'Configurator',
        )[0];

        if (cat in catMap || ignore.includes(cat)) {
          return;
        }

        catMap[cat] = { name: cat, img: product.img };
      });

      return catMap;
    },
  },
};
</script>
